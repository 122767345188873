* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Noto Sans", sans-serif;
}

@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,100..900;1,100..900&display=swap');
#root {
  height: 100vh;
  display: flex;
  background: #ffffff;
}
.logo{
  width: 200px;
  align-items: center;
}
.App{
  margin: 0 auto;
}
.message{
  text-align: center;
  font-size: 1.5rem;
  margin: 20px 0;
  color: #1b1d48;
  line-height: 0.5;
}
.game-pass-message{
  text-align: center;
  font-size: 1rem;
  font-weight: 600;
  margin: 20px 0;
  color: #1b1d48;
  line-height: 0.5;
}
.end-message{
  text-align: center;
  font-size: 1.5rem;
  font-weight: 600;
  margin: 40% 0;
  color: #1b1d48;
  line-height: 1;
}
.memory-game {
  width: 410px;
  height: 410px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  perspective: 1000px;
}

.numberCircle {
  border-radius: 10%;
  display:inline-block;
  width: 25px;
  height: 20px;
  padding: 4px;

  background: #fff;
  border: 2px solid #0ad7b0;;
  color: #0ad7b0;;
  text-align: center;
}

.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.memory-card {
  width: calc(25% );
  height: calc(25%);
  margin: 5px;
  position: relative;
  transform: scale(1);
  transform-style: preserve-3d;
  transition: transform 0.5s;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3);
  cursor: pointer;
}

.memory-card:active {
  transform: scale(0.97);
  transition: transform 0.2s;
}

.memory-card.flip {
  transform: rotateY(180deg);
}

.front-face,
.back-face {
  width: 100%;
  height: 100%;
  position: absolute;
  background: #0ad7b0;
  backface-visibility: hidden;
}

.front-face {
  transform: rotateY(180deg);
}
.congText{
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 28px;
}
.winText{
  font-size: 1rem;
  font-weight: 550;
  color: #03364e;
  margin-left: 24px;
  margin-right: 24px;
  margin-bottom: 28px;
}